import React, { FC } from "react"
import { useSelector } from "react-redux"
import { ReservedFields } from "@/onboarding/features/product/models/Product"
import { getFieldSettings, getSectionChat } from "@/onboarding/features/product/selectors"
import _ from "lodash"
import SectionHolder from "./shared/SectionHolder"
import Content from "./extra_fees/Content"
import Edit from "./extra_fees/Edit"

const ExtraFees: FC = () => {
  const { extra_fees } = useSelector(getFieldSettings(ReservedFields.EXTRA_FEES))
  const sectionChat = useSelector(getSectionChat(_.snakeCase("extraFees")))

  return (
    <SectionHolder
      title="Extra Fees"
      editComponent={extra_fees.writeable ? Edit : null}
      editMethod="inline"
      showLockedIcon={!extra_fees.writeable}
      chat={sectionChat}
    >
      <Content />
    </SectionHolder>
  )
}

export default ExtraFees
