import React, { useContext, useState } from "react"

import { Col, ListGroup, Row, Button } from "react-bootstrap"

import { useModal } from "@/helpers/useModal"
import UnsavedChangesModal from "@/components/Wizard/UnsavedChangesModal"
import StatusIcon from "./StatusIcon"

import WizardContext, {
  previousPage as getPreviousPage,
  nextPage as getNextPage,
  useValidity,
  isPageEqual,
} from "../WizardContext"

const TabTitle = ({ page }) => {
  const [isValid] = useValidity(page)

  return (
    <div className="d-flex justify-content-between">
      <Col sm="auto"> {page.title} </Col>
      <Col sm="auto">
        <StatusIcon isValid={isValid} />
      </Col>
    </div>
  )
}

/**
 * Calculate forms validity on a hidden component.
 *
 * We assume pages will use Formik for their forms as part of this Wizard, and the only way
 * Formik will run validations is if the component is mounted.
 *
 */
const InactivePageComponent = ({ page, context }) => {
  const Component = page.component

  const [isValid, setValidity] = useValidity(page)

  return (
    <div style={{ display: "none" }}>
      <Component
        title={page.title}
        formNavigation={[]}
        context={context}
        setValidity={setValidity}
      />
    </div>
  )
}

const ActivePageComponent = ({ page, context, formNavigation }) => {
  const Component = page.component

  const [isValid, setValidity] = useValidity(page)

  return (
    <Component
      title={page.title}
      formNavigation={formNavigation}
      context={context}
      setValidity={setValidity}
    />
  )
}

const PageBar = ({ activePageHasUnsavedChanges = false }) => {
  const { activePage, setPage, pages, context } = useContext(WizardContext)
  const [selectedPage, setSelectedPage] = useState(null)

  if (pages.length === 0) {
    return <p>There is currently no available content to edit.</p>
  }

  // Build up the form navigation
  const formNavigation = []

  const previousPage = getPreviousPage()

  const nextPage = getNextPage()

  if (previousPage) {
    formNavigation.push(
      <Button key="back" onClick={() => setPage(previousPage)}>
        Back
      </Button>
    )
  }

  if (nextPage) {
    formNavigation.push(
      <Button key="next" onClick={() => setPage(nextPage)}>
        Next
      </Button>
    )
  }

  const openUnsavedChangesModal = useModal(({ show, onHide }) => (
    <UnsavedChangesModal
      show={show}
      onHide={onHide}
      onConfirm={() => {
        setPage(selectedPage)
        setSelectedPage(null)
      }}
    />
  ))

  const handleTabClick = (page) => {
    if (activePageHasUnsavedChanges) {
      setSelectedPage(page)
      openUnsavedChangesModal()
    } else {
      setPage(page)
    }
  }

  return (
    <Row>
      <Col md={3}>
        <ListGroup>
          {pages.map((page) => {
            const isActive = isPageEqual(page, activePage)
            return (
              <ListGroup.Item
                href={page.href}
                key={page.uuid}
                active={isActive}
                action
                onClick={() => handleTabClick(page)}
              >
                {!isActive && <InactivePageComponent page={page} context={context} />}
                <TabTitle page={page} />
              </ListGroup.Item>
            )
          })}
        </ListGroup>
      </Col>
      <Col md={9}>
        <ActivePageComponent page={activePage} context={context} formNavigation={formNavigation} />
        <br />
      </Col>
    </Row>
  )
}

export default PageBar
