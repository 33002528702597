import React, { FC } from "react"
import { useSelector } from "react-redux"

import { getFeatures, getSectionChat } from "@/onboarding/features/product/selectors"

import _ from "lodash"
import SectionHolder from "./shared/SectionHolder"
import TargetSummary from "./forecasts/TargetSummary"
import Edit from "./forecasts/Edit"

const Forecasts: FC = () => {
  const features = useSelector(getFeatures)
  const sectionChat = useSelector(getSectionChat(_.snakeCase("forecasts")))

  return (
    <SectionHolder
      title="Financial Forecasts"
      editComponent={features.general.product.forecasts.editable ? Edit : null}
      chat={sectionChat}
    >
      <TargetSummary />
    </SectionHolder>
  )
}

export default Forecasts
