import React, { FC } from "react"
import { Col, Row, Table } from "react-bootstrap"

import FormikBootstrap from "@/components/formik-bootstrap"
import TextDisplay from "@/onboarding/features/product/components/shared/TextDisplay"
import Pricing from "@/pricing"
import ReferencedValueSelect from "@/onboarding/features/product/components/shared/ReferencedValueSelect"
import { ReferencedValueTypes } from "@/onboarding/features/product/models/Product"
import RateInputs from "../../shared/RateInputs"

const WriteView: FC = () => (
  <div className="d-flex flex-column gap-2">
    <Row>
      <Col md={8}>
        <h5>Edit Details</h5>
        <Row className="mb-2">
          <Col md={5}>
            <ReferencedValueSelect
              name="guideType"
              type={ReferencedValueTypes.GUIDE_TYPE}
              label="Guide Type"
            />
          </Col>

          <Col md={5}>
            <FormikBootstrap.TextInput name="name" label="Name" />
          </Col>
        </Row>

        <Row className="mb-2">
          <Col md={5}>
            <FormikBootstrap.TextInput name="notes" label="Notes" />
          </Col>
          <Col md={5}>
            <FormikBootstrap.TextInput name="languages" label="Languages" />
          </Col>
        </Row>
        <Row className="mb-2">
          <Col md={10}>
            <FormikBootstrap.TextInput name="description" label="Description" />
          </Col>
        </Row>
        <Row>
          <Col md={3} className="mt-4">
            <FormikBootstrap.Checkbox name="licensedGuide" label="Licensed Guide?" />
          </Col>
          <Col md={3} className="mt-4">
            <FormikBootstrap.Checkbox name="stepOnGuide" label="Step on Guide?" />
          </Col>
          <Col md={3} className="mt-4">
            <FormikBootstrap.Checkbox name="pierToPier" label="Pier to Pier?" />
          </Col>
        </Row>
      </Col>

      <Col md={4} className="ms-n4">
        <h5>Edit Rates</h5>
        <RateInputs name="ratesheet" />
      </Col>
    </Row>
  </div>
)

const ReadView = ({ component, hideRates }): JSX.Element => (
  <div className="d-flex flex-column gap-2">
    <h5>Details</h5>
    <Table>
      <thead>
        <tr>
          <th>Guide Type</th>
          <th>Name</th>
          <th>Description</th>
          <th>Notes</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td aria-label="Guide Type">
            <TextDisplay value={component.guideType} />
          </td>
          <td aria-label="Name">
            <TextDisplay value={component.name} />
          </td>
          <td aria-label="Description">
            <TextDisplay value={component.description} />
          </td>
          <td aria-label="Notes">
            <TextDisplay value={component.notes} />
          </td>
        </tr>
      </tbody>
      <thead>
        <tr>
          <th>Languages</th>
          <th>Licensed Guide?</th>
          <th>Step on Guide?</th>
          <th>Pier to Pier?</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td aria-label="Languages">
            <TextDisplay value={component.languages} />
          </td>
          <td aria-label="Licensed Guide?">
            <TextDisplay value={component.licensedGuide} />
          </td>
          <td aria-label="Step on Guide?">
            <TextDisplay value={component.stepOnGuide} />
          </td>
          <td aria-label="Pier to Pier?" colSpan={2}>
            <TextDisplay value={component.pierToPier} />
          </td>
        </tr>
      </tbody>
    </Table>
    {!hideRates && (
      <Col md={3}>
        <h5 className="mt-2">Rates</h5>
        <Pricing.View value={component.ratesheet} />
      </Col>
    )}
  </div>
)

const View = ({ component, editing = false, hideRates = false }): JSX.Element =>
  editing ? <WriteView /> : <ReadView component={component} hideRates={hideRates} />

export default View
