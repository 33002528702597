import React, { useState, useEffect } from "react"
import { Button, Card, Col } from "react-bootstrap"
import { useDispatch, useSelector } from "react-redux"
import ProcurementOnly from "@/auth_descriptor/ProcurementOnly"
import CruiseLineOnly from "@/auth_descriptor/CruiseLineOnly"
import OperatorOnly from "@/auth_descriptor/OperatorOnly"
import { insertAnd } from "@/insurance/features/helpers"
import { ChatButton } from "@/components/chat"
import { getProfile, getStatusLabels } from "../selectors"
import InsuranceUploadReview from "./InsuranceUploadReview"
import EditUpload from "./EditUpload"
import ViewUpload from "./ViewUpload"
import StatusIcon from "../../StatusIcon"
import NewUpload from "./NewUpload"
import ExpiredUploads from "./ExpiredInsuranceUploads"
import { updateEditStateUploads, updateNewUploads } from "../Slice"

const InsuranceUploads = () => {
  const { _view, editStateUploads, newUploads } = useSelector(getProfile)
  const { insuranceUploads } = _view.value.profile
  const { newUpload } = _view.value.profile
  const { expiredUploads } = _view.value.profile
  const [editUploads, setEditUploads] = useState(editStateUploads)
  const [draftUploads, setDraftUploads] = useState(newUploads)
  const statusLabels = useSelector(getStatusLabels)

  const dispatch = useDispatch()

  useEffect(() => {
    setEditUploads(editStateUploads)
    setDraftUploads(newUploads)
  }, [editStateUploads, newUploads])

  const onEdit = (certificateId) => {
    const newState = {
      ...editUploads,
      [certificateId]: !editUploads[certificateId],
    }

    setEditUploads(newState)

    dispatch(updateEditStateUploads({ editStateUploads: newState }))
  }

  const getCategoryString = (certificate) => {
    const categoryString = certificate.categories.join(", ")
    return insertAnd(categoryString)
  }

  const onNewUpload = () => {
    const newDraftUpload = { id: draftUploads.length, data: newUpload }
    const updatedDraftUploads = [...draftUploads, newDraftUpload]
    setDraftUploads(updatedDraftUploads)
    dispatch(updateNewUploads({ newUploads: updatedDraftUploads }))
  }

  const onCancel = (cancelledId) => {
    const updatedDraftUploads = draftUploads.filter((upload) => upload.id !== cancelledId)
    setDraftUploads(updatedDraftUploads)
    dispatch(updateNewUploads({ newUploads: updatedDraftUploads }))
  }

  return (
    <>
      {insuranceUploads.length === 0 ? (
        <div key="firstCertificate">
          <CruiseLineOnly>
            <div className="col-sm-8">
              <h6 className="mt-2 mb-4 ms-3">
                <StatusIcon status="incomplete" label="incomplete" />
                {expiredUploads.length === 0
                  ? " No insurance submission received"
                  : " No new insurance submission received"}
              </h6>
            </div>
          </CruiseLineOnly>

          <OperatorOnly>
            <Card className="pb-4 col-sm-8 mb-3">
              <h5 className="mt-2 ms-3">Certificate Upload</h5>
              <div className="mt-2 mb-4 ms-3">
                <StatusIcon status={newUpload.status} label={statusLabels[newUpload.status]} />
                {expiredUploads.length === 0
                  ? `${"  "} Insurance certificate upload required`
                  : `${"  "} New insurance certificate upload required`}
              </div>
              <EditUpload form={newUpload} fileSrc={newUpload.fileSrc} />
            </Card>
          </OperatorOnly>
        </div>
      ) : (
        insuranceUploads.map((upload) => (
          <div key={upload.certificate.certificateId} className="col-sm-8">
            <Card className="pb-4 mb-3">
              <h5 className="mt-2 ms-3">Certificate Upload</h5>
              <OperatorOnly>
                {!editUploads[upload.certificate.certificateId] && (
                  <>
                    <h6 className="mt-1 mb-1 ms-3">
                      {`Insurance certificate for ${getCategoryString(upload.certificate)}`}
                    </h6>
                    <div className="mt-2 mb-4 ms-3">
                      <StatusIcon
                        status={upload.certificate.status}
                        label={statusLabels[upload.certificate.status]}
                      />
                      {upload.certificate.status === "pending"
                        ? `${"  "} Your Insurance certificate status is ${upload.certificate.statusLabel}`
                        : `${"  "} Your Insurance certificate upload is ${upload.certificate.statusLabel}`}
                    </div>
                  </>
                )}

                {editUploads[upload.certificate.certificateId] ? (
                  <EditUpload form={upload.certificate} fileSrc={upload.certificate.fileSrc} />
                ) : (
                  <ViewUpload form={upload.certificate} fileSrc={upload.certificate.fileSrc} />
                )}
                {!editUploads[upload.certificate.certificateId] &&
                  !(
                    upload.certificate.status === "approved" ||
                    upload.certificate.status === "approved_with_exception"
                  ) && (
                    <Col>
                      <Button
                        onClick={() => onEdit(upload.certificate.certificateId)}
                        className="btn-primary position-absolute end-0 me-3 mt-n4"
                      >
                        Edit
                      </Button>
                    </Col>
                  )}
              </OperatorOnly>
              {!editUploads[upload.certificate.certificateId] && (
                <Col className="position-absolute end-0 me-3 mt-2">
                  {upload.chat && (
                    <ChatButton chat={upload.chat} key={upload.chat.id} buttonStyle />
                  )}
                </Col>
              )}
              <CruiseLineOnly>
                <h6 className="mt-1 mb-1 ms-3">
                  {`Insurance certificate for ${getCategoryString(upload.certificate)}`}
                </h6>
                <div className="mt-2 mb-4 ms-3">
                  <StatusIcon
                    status={upload.certificate.status}
                    label={statusLabels[upload.certificate.status]}
                  />
                  {upload.certificate.status === "pending"
                    ? `${"  "} Review pending`
                    : `${"  "} This insurance upload is marked as ${upload.certificate.statusLabel}`}
                </div>

                <div className="mt-2 mb-4 ms-3">
                  <ViewUpload form={upload.certificate} fileSrc={upload.certificate.fileSrc} />
                </div>
              </CruiseLineOnly>
            </Card>

            <ProcurementOnly>
              <InsuranceUploadReview form={upload.certificate} />
            </ProcurementOnly>
          </div>
        ))
      )}

      {draftUploads.map((upload) => (
        <NewUpload key={upload.id} upload={upload} onCancel={() => onCancel(upload.id)} />
      ))}

      <OperatorOnly>
        <div className="col-sm-8 d-flex justify-content-end">
          <Button className="btn-secondary" onClick={onNewUpload}>
            <i className="fas fa-plus me-2" />
            New Upload
          </Button>
        </div>
      </OperatorOnly>

      <ExpiredUploads expiredUploads={expiredUploads} />
    </>
  )
}

export default InsuranceUploads
