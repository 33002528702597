import React from "react"
import { useSelector } from "react-redux"
import { getView } from "../selectors"
import ConstraintsTable from "./ConstraintsTable"

const CapacityAllotments = (): JSX.Element => {
  const view = useSelector(getView)
  return (
    <div className="ms-4 mb-4">
      <h5 className="mt-4">{`Capacity Requirements for ${view.inventory.tour.code}: ${view.inventory.tour.name}`}</h5>
      <h6>{`${view.inventory.ship.name} in ${view.inventory.port.name}`}</h6>
      <ConstraintsTable />
    </div>
  )
}

export default CapacityAllotments
