import React from "react"
import { Button, Form } from "react-bootstrap"
import { useDispatch } from "react-redux"
import { Formik } from "formik"

import { updateFields } from "@/onboarding/features/product/Slice"

const FormikFieldSubmitter = ({ initialValues, children = null }): JSX.Element => {
  const dispatch = useDispatch()

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      onSubmit={(values, { setSubmitting }) => {
        setSubmitting(true)
        dispatch(updateFields({ values }))
        setSubmitting(false)
      }}
    >
      {({ handleSubmit, handleReset, isSubmitting, dirty }) => (
        <Form className="d-flex flex-column gap-3" onSubmit={handleSubmit}>
          {children}
          <div className="d-flex gap-3">
            <Button type="submit" disabled={isSubmitting || !dirty}>
              Submit
            </Button>
            {dirty && (
              <Button variant="danger" disabled={isSubmitting} onClick={handleReset}>
                Cancel
              </Button>
            )}
          </div>
        </Form>
      )}
    </Formik>
  )
}

export default FormikFieldSubmitter
