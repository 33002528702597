import React, { createContext, useContext, useState } from "react"

export const UnsavedChangesContext = createContext()

export const useUnsavedChanges = () => useContext(UnsavedChangesContext)

export const UnsavedChangesProvider = ({ children }) => {
  const [unsavedChanges, setUnsavedChanges] = useState({})

  return (
    <UnsavedChangesContext.Provider value={{ unsavedChanges, setUnsavedChanges }}>
      {children}
    </UnsavedChangesContext.Provider>
  )
}
