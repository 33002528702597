import React, { FC } from "react"

import { useSelector } from "react-redux"
import { isBlank } from "@/utils"
import _ from "lodash"
import SectionHolder from "./shared/SectionHolder"
import Details from "./tour_stops/Details"
import Edit from "./tour_stops/Edit"
import Timeline from "./tour_stops/Timeline"
import {
  getSectionFieldNames,
  countIncompleteRequiredFields,
  getField,
  getSectionChat,
} from "../selectors"
import { ReservedFields } from "../models/Product"

const TourStops: FC = () => {
  const fieldNames = useSelector(getSectionFieldNames.tourLocation)
  const tourStopsField = useSelector(getField(ReservedFields.TOUR_STOPS))
  const sectionChat = useSelector(getSectionChat(_.snakeCase("tourStops")))

  const incompleteFieldCount = useSelector((state) => {
    const baseCount = countIncompleteRequiredFields(...fieldNames)(state)
    return isBlank(tourStopsField.value) && tourStopsField.required ? baseCount + 1 : baseCount
  })

  return (
    <SectionHolder
      title="Tour Itinerary"
      editComponent={Edit}
      incompleteRequiredFieldCount={incompleteFieldCount}
      chat={sectionChat}
    >
      <Details />
      <Timeline
        requiredField={tourStopsField.required}
        incompleteField={isBlank(tourStopsField.value)}
      />
    </SectionHolder>
  )
}
export default TourStops
