import React, { FC } from "react"
import { Button } from "react-bootstrap"
import { useDispatch, useSelector } from "react-redux"

import { toggleFormsEditable } from "@/onboarding/features/product/Slice"
import { canEdit, getFormsEditable } from "@/onboarding/features/product/selectors"

const ToggleFormsButton: FC = () => {
  const dispatch = useDispatch()
  const isEditable = useSelector(canEdit)
  const formsEditable = useSelector(getFormsEditable)

  if (!isEditable) {
    return null
  }

  const text = formsEditable ? "View Mode" : "Edit Mode"

  return (
    <div>
      <Button variant="outline-primary" onClick={() => dispatch(toggleFormsEditable())}>
        {text}
      </Button>
    </div>
  )
}

export default ToggleFormsButton
