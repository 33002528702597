import { createSlice } from "@reduxjs/toolkit"
import _ from "lodash"
import Api from "@/api"
import { newAxiosErrorHandler, newSuccessHandler } from "@/osn"
import * as AsyncField from "@/helpers/AsyncField"
import { LoadState } from "@/helpers/AsyncField"
import { getProfile } from "./selectors"

export const submitContractedIds = AsyncField.createLoadThunk(
  "insurance/submitContractedIds",
  (_action) => "_view",
  async (pendingContractedIds, thunkAPI) => {
    const { profileId } = getProfile(thunkAPI.getState())

    const response = await Api.insurances
      .submitContractedIds(pendingContractedIds, profileId)
      .then(newSuccessHandler(thunkAPI.dispatch))
      .catch(newAxiosErrorHandler(thunkAPI.dispatch))
    return { value: response.data.data.view }
  }
)

export const uploadInsuranceCertificate = AsyncField.createLoadThunk(
  "insurance/uploadInsuranceDocument",
  (_action) => "_view",
  async (values, thunkAPI) => {
    const { profileId } = getProfile(thunkAPI.getState())
    const response = await Api.insurances
      .uploadInsuranceCertificate(values, profileId)
      .then(newSuccessHandler(thunkAPI.dispatch))
      .catch(newAxiosErrorHandler(thunkAPI.dispatch))
    return { value: response.data.data.view }
  }
)

export const updateInsuranceCertificate = AsyncField.createLoadThunk(
  "insurance/updateInsuranceDocument",
  (_action) => "_view",
  async (values, thunkAPI) => {
    const { profileId } = getProfile(thunkAPI.getState())

    const response = await Api.insurances
      .updateInsuranceCertificate(values, profileId)
      .then(newSuccessHandler(thunkAPI.dispatch))
      .catch(newAxiosErrorHandler(thunkAPI.dispatch))
    return { value: response.data.data.view }
  }
)

const Slice = createSlice({
  name: "profile",
  initialState: {
    initialized: false,
    isTableLoading: false,
    editingCategories: true,
    editingReview: true,
    contractedIds: [],
    pendingContractedIds: [],
    _view: {},
    profileId: "",
    isNewUpload: {},
    newUploads: [],
  },
  reducers: {
    initialize: (state, { payload }) => ({
      ...state,
      initialized: true,
      _view: AsyncField.createField({
        value: payload.view,
        defaultValue: payload.view,
        state: LoadState.Ready,
      }),
      profileId: payload.profileId,
      contractedIds: payload.view.profile.contractedVehicleIds,
      pendingContractedIds: payload.view.profile.contractedVehicleIds,
      statusLabels: payload.view.profile.statusLabels,
      editingCategories: true,
      editStateUploads: {},
      editingReview: true,
      isNewUpload: false,
      newUploads: [],
    }),
    setTableLoading: (state, { payload }) => ({
      ...state,
      isTableLoading: payload.isTableLoading,
    }),
    typeContracted: (state, { payload }) => ({
      ...state,
      pendingContractedIds: _.union(state.pendingContractedIds, [payload.vehicleTypeID]),
    }),
    typeUncontracted: (state, { payload }) => ({
      ...state,
      pendingContractedIds: _.difference(state.pendingContractedIds, [payload.vehicleTypeID]),
    }),
    setIsNewUpload: (state, { payload }) => ({
      ...state,
      isNewUpload: payload.isNewUpload,
    }),
    updateView: (state, { payload }) => ({
      ...state,
      _view: payload.view,
    }),
    updateEditStateUploads: (state, { payload }) => ({
      ...state,
      editStateUploads: payload.editStateUploads,
    }),
    updateNewUploads: (state, { payload }) => ({
      ...state,
      newUploads: payload.newUploads,
    }),
  },
  extraReducers: {
    ...submitContractedIds.reducers,
    ...uploadInsuranceCertificate.reducers,
    ...updateInsuranceCertificate.reducers,
  },
})

export const {
  initialize,
  setTableLoading,
  typeContracted,
  typeUncontracted,
  setIsNewUpload,
  updateView,
  updateEditStateUploads,
  updateNewUploads,
} = Slice.actions

export default Slice
