/* eslint-disable react/no-danger */
import React, { useContext } from "react"
import { useDispatch } from "react-redux"
import { Formik } from "formik"

import FormikBootstrap from "@/components/formik-bootstrap"

import { newAxiosErrorHandler } from "@/osn"

import keyboard from "@/utils/keyboard"

import AlwaysScrollToBottom from "@/components/AlwaysScrollToBottom"
import TextAreaInput from "@/components/TextAreaInput"
import { formatDate, parseDateTime } from "@/components/DatePickers"

import Actions from "./actions"
import ChatContext from "./ChatContext"
import * as Types from "./types"

// styling
const profilePictureStyle = { width: "25px", height: "100%", borderRadius: "50%" }
const incomingMessageStyle = {
  borderRadius: "15px",
  backgroundColor: "rgba(57, 192, 237,.2)",
  width: "15rem",
}
const outgoingMessageStyle = { borderRadius: "15px", backgroundColor: "#fbfbfb", width: "15rem" }

const ProfilePicture = ({ message }): JSX.Element => (
  <img
    src={message.senderProfilePicturePath}
    alt={`${message.senderName}`}
    style={profilePictureStyle}
  />
)

const IncomingMessage = ({ message }): JSX.Element => (
  <div className="d-flex flex-row justify-content-start">
    <ProfilePicture message={message} />
    <div className="p-3 pt-0 ms-1" style={incomingMessageStyle}>
      <div className="pt-2 pb-1 text-start text-muted" style={{ fontSize: "11px" }}>
        {message.senderName}
      </div>
      <div dangerouslySetInnerHTML={{ __html: message.body }} />
      <div className="text-end text-muted" style={{ fontSize: "11px" }}>
        {formatDate(parseDateTime(message.sentAt), "datetime.humaneShort")}
      </div>
    </div>
  </div>
)

const OutgoingMessage = ({ message }): JSX.Element => (
  <div className="d-flex flex-row justify-content-end">
    <div className="p-3 pt-0 me-3 border" style={outgoingMessageStyle}>
      <div className="pt-2 pb-1 text-start text-muted" style={{ fontSize: "11px" }}>
        You
      </div>

      <div className="fs-5" dangerouslySetInnerHTML={{ __html: message.body }} />
      <div className="text-end text-muted" style={{ fontSize: "11px" }}>
        {formatDate(parseDateTime(message.sentAt), "datetime.humaneShort")}
      </div>
    </div>
    <ProfilePicture message={message} />
  </div>
)

const Message = ({ message }): JSX.Element =>
  message.isSender ? <OutgoingMessage message={message} /> : <IncomingMessage message={message} />

const Input = ({ placeholder, isSubmitting, handleSubmit }): JSX.Element => (
  <>
    {/* Used to ensure correct spacing */}
    <TextAreaInput
      key="placeholder"
      rows={4}
      label=""
      name="__messagePlaceholder"
      className="invisible"
    />
    <div
      key="input"
      className="form-outline"
      style={{ position: "absolute", bottom: 0, left: 0, right: 0 }}
    >
      <div className="ms-1 me-1">
        <FormikBootstrap.TextAreaInput
          placeholder={placeholder}
          validationStyling="hidden"
          rows={4}
          name="message"
          disabled={isSubmitting}
          onKeyDown={(event) => keyboard.isEnterOnly(event) && handleSubmit()}
        />
      </div>
    </div>
  </>
)

const InputBox = ({
  placeholder,
  sendMessage,
}: {
  placeholder: string
  sendMessage: Types.SendMessageFn
}): JSX.Element => {
  const dispatch = useDispatch()

  return (
    <Formik
      initialValues={{ message: "" }}
      onSubmit={(values, { setSubmitting, resetForm }) => {
        setSubmitting(true)

        sendMessage({ message: values.message })
          .catch(newAxiosErrorHandler(dispatch))
          .then(() => resetForm())

        setSubmitting(false)
      }}
    >
      {({ isSubmitting, handleSubmit }) => (
        <Input placeholder={placeholder} isSubmitting={isSubmitting} handleSubmit={handleSubmit} />
      )}
    </Formik>
  )
}

const ReplyBox = (): JSX.Element => {
  const { conversation } = useContext(ChatContext)

  if (conversation.messages.length > 0) {
    return (
      <InputBox
        placeholder="Type a message"
        sendMessage={({ message }) =>
          Actions.sendMessage({ conversationId: conversation.id, body: message })
        }
      />
    )
  }

  return (
    <InputBox
      placeholder="Start a conversation"
      sendMessage={({ message }) =>
        Actions.sendMessage({
          conversationId: conversation.id,
          recipient: conversation.recipient,
          subject: conversation.subject,
          body: message,
        })
      }
    />
  )
}

const ConversationBody = (): JSX.Element => {
  const { conversation } = useContext(ChatContext)
  // TODO Applying h-100 here to fill the entire box up causes a weird stretching issue with the profile pic
  // TODO the user's name isn't displayed anywhere
  return (
    <div className="d-flex flex-column justify-content-end gap-3">
      {conversation.messages.map((message) => (
        <Message key={message.id} message={message} />
      ))}
      <AlwaysScrollToBottom />
      <ReplyBox />
    </div>
  )
}

export default ConversationBody
