import React, { FC } from "react"
import { Col, Row } from "react-bootstrap"
import { useSelector } from "react-redux"

import {
  getProductField,
  getSectionFieldNames,
  getSettings,
} from "@/onboarding/features/product/selectors"
import TextDisplay from "@/onboarding/features/product/components/shared/TextDisplay"
import { ReservedFields } from "@/onboarding/features/product/models/Product"

const DisplayValue = ({
  name,
  label,
  requiredField = false,
  incompleteField = false,
}): JSX.Element => {
  const { value } = useSelector(getProductField(name))

  return (
    <div className="d-flex flex-column gap-1">
      <span>
        <b>
          {`${label}`}
          {requiredField && incompleteField && (
            <span
              data-toggle="tooltip"
              data-placement="top"
              title="This is an incomplete required field"
              className="ms-1 me-1"
              style={{ color: "red" }}
            >
              *
            </span>
          )}
        </b>
      </span>
      <TextDisplay value={value} />
    </div>
  )
}

const HorizontalDisplayValue = ({
  name,
  label,
  requiredField = false,
  incompleteField = false,
}): JSX.Element => {
  const { value } = useSelector(getProductField(name))

  return (
    <div className="d-flex gap-1">
      <span>
        <b style={{ fontWeight: "430" }}>
          {`${label}`}
          {requiredField && incompleteField && (
            <span
              data-toggle="tooltip"
              data-placement="top"
              title="This is an incomplete required field"
              className="ms-1 me-2"
              style={{ color: "red" }}
            >
              *
            </span>
          )}
        </b>
      </span>
      <TextDisplay value={value} />
    </div>
  )
}

const Content: FC = () => {
  const fieldNames = useSelector(getSectionFieldNames.additionalDetails)
  const fieldsSettings = useSelector(getSettings(...fieldNames))

  return (
    <div className="d-flex flex-column gap-4">
      <Row>
        <Col>
          <h3 className="h6">Tour Restrictions:</h3>
          <Row className="f-flex gap-4">
            <Col>
              <HorizontalDisplayValue
                name={ReservedFields.AGE_RESTRICTIONS}
                label="Age"
                requiredField={fieldsSettings[ReservedFields.AGE_RESTRICTIONS].required}
                incompleteField={fieldsSettings[ReservedFields.AGE_RESTRICTIONS].incomplete}
              />
            </Col>
            <Col>
              <HorizontalDisplayValue
                name={ReservedFields.HEIGHT_RESTRICTIONS}
                label="Height"
                requiredField={fieldsSettings[ReservedFields.HEIGHT_RESTRICTIONS].required}
                incompleteField={fieldsSettings[ReservedFields.HEIGHT_RESTRICTIONS].incomplete}
              />
            </Col>
            <Col>
              <HorizontalDisplayValue
                name={ReservedFields.WEIGHT_RESTRICTIONS}
                label="Weight"
                requiredField={fieldsSettings[ReservedFields.WEIGHT_RESTRICTIONS].required}
                incompleteField={fieldsSettings[ReservedFields.WEIGHT_RESTRICTIONS].incomplete}
              />
            </Col>
            <Col>
              <HorizontalDisplayValue
                name={ReservedFields.MEDICAL_CONDITION_RESTRICTIONS}
                label="Medical Conditions"
                requiredField={
                  fieldsSettings[ReservedFields.MEDICAL_CONDITION_RESTRICTIONS].required
                }
                incompleteField={
                  fieldsSettings[ReservedFields.MEDICAL_CONDITION_RESTRICTIONS].incomplete
                }
              />
            </Col>
          </Row>
        </Col>
      </Row>
      <Row className="d-flex gap-4">
        <Col>
          <h6>This tour provides:</h6>
          <Row>
            <HorizontalDisplayValue
              name={ReservedFields.INSTRUCTORS_PROVIDED}
              label="Instructors:"
              requiredField={fieldsSettings[ReservedFields.INSTRUCTORS_PROVIDED].required}
              incompleteField={fieldsSettings[ReservedFields.INSTRUCTORS_PROVIDED].incomplete}
            />
            <HorizontalDisplayValue
              name={ReservedFields.CHANGING_FACILITIES_PROVIDED}
              label="Changing Facilities:"
              requiredField={fieldsSettings[ReservedFields.CHANGING_FACILITIES_PROVIDED].required}
              incompleteField={
                fieldsSettings[ReservedFields.CHANGING_FACILITIES_PROVIDED].incomplete
              }
            />
            <HorizontalDisplayValue
              name={ReservedFields.STORAGE_LOCKERS_PROVIDED}
              label="Storage Lockers:"
              requiredField={fieldsSettings[ReservedFields.STORAGE_LOCKERS_PROVIDED].required}
              incompleteField={fieldsSettings[ReservedFields.STORAGE_LOCKERS_PROVIDED].incomplete}
            />
            <HorizontalDisplayValue
              name={ReservedFields.TOWELS_PROVIDED}
              label="Towels For Water Activities:"
              requiredField={fieldsSettings[ReservedFields.TOWELS_PROVIDED].required}
              incompleteField={fieldsSettings[ReservedFields.TOWELS_PROVIDED].incomplete}
            />
            <HorizontalDisplayValue
              name={ReservedFields.WAIVER_FORMS_REQUIRED}
              label="Waiver forms to be signed:"
              requiredField={fieldsSettings[ReservedFields.WAIVER_FORMS_REQUIRED].required}
              incompleteField={fieldsSettings[ReservedFields.WAIVER_FORMS_REQUIRED].incomplete}
            />
          </Row>
        </Col>
        <Col>
          <h6>This tour is suitable for people with:</h6>
          <Row>
            <HorizontalDisplayValue
              name={ReservedFields.WHEELCHAIR_SUITABLE}
              label="Wheelchairs:"
              requiredField={fieldsSettings[ReservedFields.WHEELCHAIR_SUITABLE].required}
              incompleteField={fieldsSettings[ReservedFields.WHEELCHAIR_SUITABLE].incomplete}
            />
            <HorizontalDisplayValue
              name={ReservedFields.BLIND_SUITABLE}
              label="Blindness:"
              requiredField={fieldsSettings[ReservedFields.BLIND_SUITABLE].required}
              incompleteField={fieldsSettings[ReservedFields.BLIND_SUITABLE].incomplete}
            />
            <HorizontalDisplayValue
              name={ReservedFields.DEAF_SUITABLE}
              label="Deafness:"
              requiredField={fieldsSettings[ReservedFields.DEAF_SUITABLE].required}
              incompleteField={fieldsSettings[ReservedFields.DEAF_SUITABLE].incomplete}
            />
            <HorizontalDisplayValue
              name={ReservedFields.WALKING_DIFFICULTY_SUITABLE}
              label="Walking Difficulties:"
              requiredField={fieldsSettings[ReservedFields.WALKING_DIFFICULTY_SUITABLE].required}
              incompleteField={
                fieldsSettings[ReservedFields.WALKING_DIFFICULTY_SUITABLE].incomplete
              }
            />
          </Row>
        </Col>
        <Col>
          <DisplayValue
            name={ReservedFields.INCLUDED_EQUIPMENT}
            label="What equipment is included on tour?"
            requiredField={fieldsSettings[ReservedFields.INCLUDED_EQUIPMENT].required}
            incompleteField={fieldsSettings[ReservedFields.INCLUDED_EQUIPMENT].incomplete}
          />
        </Col>
        <Col>
          <DisplayValue
            name={ReservedFields.GUEST_GUIDANCE}
            label="What should guests be advised to bring on tour?"
            requiredField={fieldsSettings[ReservedFields.GUEST_GUIDANCE].required}
            incompleteField={fieldsSettings[ReservedFields.GUEST_GUIDANCE].incomplete}
          />
        </Col>
      </Row>
    </div>
  )
}

export default Content
