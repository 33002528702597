import React, { FC } from "react"
import { Table } from "react-bootstrap"
import { useSelector } from "react-redux"

import { getVisitsByShip } from "@/itinerary_dashboard/selectors"
import { rowStyle } from "@/itinerary_dashboard/helpers"

import PortCell from "./PortCell"
import VisitTimeDisplay from "./VisitTimeDisplay"

const Row = ({ visit }) => (
  <tr style={rowStyle(visit.date)}>
    <td>{visit.formattedDate}</td>
    <td>{visit.ship.name}</td>
    <td>
      <PortCell visit={visit} />
    </td>
    <td>{visit.port.country}</td>
    <td>{visit.pier}</td>
    <td>{visit.berthingMethod}</td>
    <td>{visit.itinerary.name}</td>
    <td>{visit.callType}</td>
    <td>{visit.charter ? "Yes" : "No"}</td>
    <td>
      <VisitTimeDisplay time={visit.arrivalTime} />
    </td>
    <td>
      <VisitTimeDisplay time={visit.departureTime} />
    </td>
  </tr>
)

const ShipTable: FC = () => {
  const visits = useSelector(getVisitsByShip)

  return (
    <Table responsive>
      <thead className="thead-light">
        <tr>
          <th scope="col">Date</th>
          <th scope="col">Ship</th>
          <th scope="col">Port</th>
          <th scope="col">Country</th>
          <th scope="col">Pier</th>
          <th scope="col">Berthing Method</th>
          <th scope="col">Itinerary</th>
          <th scope="col">Call Type</th>
          <th scope="col">Charter</th>
          <th scope="col">Arrival Time</th>
          <th scope="col">Departure Time</th>
        </tr>
      </thead>
      <tbody>
        {visits.map((visit, index) => (
          <Row key={`${visit.date}-${visit.port.id}-${index}`} visit={visit} />
        ))}
      </tbody>
    </Table>
  )
}

export default ShipTable
