import React, { FC, useState } from "react"
import { Button, Form, Table } from "react-bootstrap"
import { useDispatch, useSelector } from "react-redux"
import { Formik } from "formik"
import _ from "lodash"

import { getVehicles } from "@/onboarding/features/product/selectors"
import {
  appendFieldListItem,
  removeFieldListItem,
  updateFieldListItem,
} from "@/onboarding/features/product/Slice"
import { newComponent, ComponentType } from "@/onboarding/features/product/models/Component"
import { ReservedFields } from "@/onboarding/features/product/models/Product"
import TextDisplay from "@/onboarding/features/product/components/shared/TextDisplay"
import FormikBootstrap from "@/components/formik-bootstrap"
import EditIcon from "@/onboarding/features/product/components/shared/EditIcon"

const VehicleEditForm = ({ vehicle, index, onCancel }): JSX.Element => {
  const dispatch = useDispatch()

  return (
    <Formik
      enableReinitialize
      initialValues={vehicle}
      onSubmit={(values, { setSubmitting }) => {
        setSubmitting(true)
        dispatch(updateFieldListItem({ fieldName: ReservedFields.VEHICLES, index, value: values }))
        setSubmitting(false)
        onCancel()
      }}
    >
      {({ handleSubmit, isSubmitting }) => (
        <Form className="d-flex flex-column gap-3" onSubmit={handleSubmit}>
          <FormikBootstrap.TextInput name="name" label="Name" />
          <FormikBootstrap.NumberInput name="quantity" label="Quantity" />
          <FormikBootstrap.NumberInput name="max_capacity" label="Max Capacity" />
          <FormikBootstrap.NumberInput name="allowed_capacity" label="Allowed Capacity" />
          <div className="d-flex gap-3">
            <Button type="submit" disabled={isSubmitting}>
              Submit
            </Button>
            <Button variant="danger" onClick={onCancel} disabled={isSubmitting}>
              Cancel
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  )
}

const VehicleEditModal: FC = () => {
  const [editingVehicle, setEditingVehicle] = useState(null)
  const dispatch = useDispatch()
  const vehicles = useSelector(getVehicles)

  const addVehicle = () => {
    dispatch(
      appendFieldListItem({
        fieldName: ReservedFields.VEHICLES,
        value: newComponent({ kind: ComponentType.VEHICLE }),
      })
    )
  }

  const cancelEdit = () => setEditingVehicle(null)

  if (editingVehicle !== null) {
    return (
      <VehicleEditForm
        vehicle={vehicles[editingVehicle]}
        index={editingVehicle}
        onCancel={cancelEdit}
      />
    )
  }

  return (
    <>
      <Table>
        <thead>
          <tr>
            <th scope="col">Name</th>
            <th scope="col" className="text-right">
              Quantity
            </th>
            <th scope="col" className="text-right">
              Max Capacity
            </th>
            <th scope="col" className="text-right">
              Allowed Capacity
            </th>
            <th scope="col" />
          </tr>
        </thead>
        <tbody>
          {_.isEmpty(vehicles) && (
            <tr>
              <td colSpan={5}>No vehicles loaded</td>
            </tr>
          )}
          {vehicles.map((vehicle, index) => (
            <tr key={`${vehicle.name}-${index}`}>
              <td>
                <TextDisplay value={vehicle.name} />
              </td>
              <td className="text-right">
                <TextDisplay value={vehicle.quantity} />
              </td>
              <td className="text-right">
                <TextDisplay value={vehicle.maxCapacity} />
              </td>
              <td className="text-right">
                <TextDisplay value={vehicle.allowedCapacity} />
              </td>
              <td>
                <div className="d-flex justify-content-end gap-3">
                  <EditIcon onEdit={() => setEditingVehicle(index)} />
                  <span
                    className="cursor-pointer text-danger"
                    onClick={() =>
                      dispatch(
                        removeFieldListItem({
                          fieldName: ReservedFields.VEHICLES,
                          index,
                        })
                      )
                    }
                  >
                    <i className="fas fa-trash" />
                  </span>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      <Button size="sm" variant="outline-primary" onClick={addVehicle}>
        <i className="fas fa-plus me-2" />
        Add Vehicle
      </Button>
    </>
  )
}

export default VehicleEditModal
