import React from "react"
import { Col, Row } from "react-bootstrap"
import { useSelector } from "react-redux"

import {
  getProductField,
  getSectionFieldNames,
  getSettings,
} from "@/onboarding/features/product/selectors"
import TextDisplay from "@/onboarding/features/product/components/shared/TextDisplay"
import { ReservedFields } from "@/onboarding/features/product/models/Product"

const DisplayValue = ({
  name,
  label,
  requiredField = false,
  incompleteField = false,
}): JSX.Element => {
  const { value } = useSelector(getProductField(name))

  return (
    <div className="d-flex flex-column gap-1">
      <span>
        <b>
          {label}
          {requiredField && incompleteField && (
            <span
              data-toggle="tooltip"
              data-placement="top"
              title="This is an incomplete required field"
              className="ms-1"
              style={{ color: "red" }}
            >
              *
            </span>
          )}
        </b>
      </span>
      <TextDisplay value={value} />
    </div>
  )
}

const Content = (): JSX.Element => {
  const fieldNames = useSelector(getSectionFieldNames.tourDetails)
  const fieldsSettings = useSelector(getSettings(...fieldNames))

  return (
    <div className="d-flex flex-column gap-3">
      <Row>
        <Col>
          <DisplayValue
            name={ReservedFields.TARGET_MINIMUM_DEPARTURE_PAX}
            label="Minimum Per Departure"
            requiredField={fieldsSettings[ReservedFields.TARGET_MINIMUM_DEPARTURE_PAX].required}
            incompleteField={fieldsSettings[ReservedFields.TARGET_MINIMUM_DEPARTURE_PAX].incomplete}
          />
        </Col>
        <Col>
          <DisplayValue
            name={ReservedFields.TARGET_MAXIMUM_DEPARTURE_PAX}
            label="Maximum Per Departure"
            requiredField={fieldsSettings[ReservedFields.TARGET_MAXIMUM_DEPARTURE_PAX].required}
            incompleteField={fieldsSettings[ReservedFields.TARGET_MAXIMUM_DEPARTURE_PAX].incomplete}
          />
        </Col>
        <Col>
          <DisplayValue
            name={ReservedFields.TARGET_MAXIMUM_CALL_PAX}
            label="Maximum Per Call"
            requiredField={fieldsSettings[ReservedFields.TARGET_MAXIMUM_CALL_PAX].required}
            incompleteField={fieldsSettings[ReservedFields.TARGET_MAXIMUM_CALL_PAX].incomplete}
          />
        </Col>
        <Col>
          <DisplayValue
            name={ReservedFields.TARGET_DEPARTURES}
            label="Departures"
            requiredField={fieldsSettings[ReservedFields.TARGET_DEPARTURES].required}
            incompleteField={fieldsSettings[ReservedFields.TARGET_DEPARTURES].incomplete}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <DisplayValue
            name={ReservedFields.TOUR_TYPE}
            label="Tour Types"
            requiredField={fieldsSettings[ReservedFields.TOUR_TYPE].required}
            incompleteField={fieldsSettings[ReservedFields.TOUR_TYPE].incomplete}
          />
        </Col>
        <Col>
          <DisplayValue
            name={ReservedFields.ACTIVITY_TYPE}
            label="Activity Type"
            requiredField={fieldsSettings[ReservedFields.ACTIVITY_TYPE].required}
            incompleteField={fieldsSettings[ReservedFields.ACTIVITY_TYPE].incomplete}
          />
        </Col>
        <Col>
          <DisplayValue
            name={ReservedFields.ACTIVITY_LEVEL}
            label="Activity Level"
            requiredField={fieldsSettings[ReservedFields.ACTIVITY_LEVEL].required}
            incompleteField={fieldsSettings[ReservedFields.ACTIVITY_LEVEL].incomplete}
          />
        </Col>
        <Col className="d-flex align-items-center">
          <DisplayValue name={ReservedFields.ADA_COMPLIANT} label="ADA Compliant" />
        </Col>
      </Row>
      <DisplayValue
        name={ReservedFields.TOUR_DESCRIPTION}
        label="Tour Description"
        requiredField={fieldsSettings[ReservedFields.TOUR_DESCRIPTION].required}
        incompleteField={fieldsSettings[ReservedFields.TOUR_DESCRIPTION].incomplete}
      />
      <DisplayValue
        name={ReservedFields.SHORT_TOUR_DESCRIPTION}
        label="Tour Description (Less Than 1000 Characters)"
        requiredField={fieldsSettings[ReservedFields.SHORT_TOUR_DESCRIPTION].required}
        incompleteField={fieldsSettings[ReservedFields.SHORT_TOUR_DESCRIPTION].incomplete}
      />
      <DisplayValue
        name={ReservedFields.TOUR_NOTES}
        label="Tour Notes"
        requiredField={fieldsSettings[ReservedFields.TOUR_NOTES].required}
        incompleteField={fieldsSettings[ReservedFields.TOUR_NOTES].incomplete}
      />
      <DisplayValue
        name={ReservedFields.TOP_THREE_REASONS}
        label="Top Three Reasons A Guest Should Buy This Tour"
        requiredField={fieldsSettings[ReservedFields.TOP_THREE_REASONS].required}
        incompleteField={fieldsSettings[ReservedFields.TOP_THREE_REASONS].incomplete}
      />
      <Row>
        <Col>
          <DisplayValue
            name={ReservedFields.UNAVAILABLE_DAYS}
            label="Days On Which The Tour Will Be Unavailable"
          />
        </Col>
        <Col>
          <DisplayValue
            name={ReservedFields.OTHER_RELEVANT_UNAVAILABLE_INFORMATION}
            label="Other Relevant Information On Days And Times The Tour Is Not Available"
          />
        </Col>
      </Row>
    </div>
  )
}

export default Content
