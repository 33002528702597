import React, { FC } from "react"
import { Button } from "react-bootstrap"
import { useDispatch, useSelector } from "react-redux"

import { toggleFormsExpanded } from "@/onboarding/features/product/Slice"
import { getFormsExpanded } from "@/onboarding/features/product/selectors"

const ToggleExpandButton: FC = () => {
  const dispatch = useDispatch()
  const formsExpanded = useSelector(getFormsExpanded)

  const text = formsExpanded ? "Collapse All" : "Expand All"

  return (
    <div>
      <Button variant="outline-primary" onClick={() => dispatch(toggleFormsExpanded())}>
        {text}
      </Button>
    </div>
  )
}

export default ToggleExpandButton
