import React, { FC } from "react"
import { useSelector } from "react-redux"

import { ReservedFields } from "@/onboarding/features/product/models/Product"
import {
  isOverriddenNetRatesheetHidden,
  isCalculatedNetRatesheetHidden,
  getFieldSettings,
} from "@/onboarding/features/product/selectors"
import StopComponentTable from "./StopComponentTable"
import GeneralComponentTable from "./GeneralComponentTable"
import OverheadTable from "./OverheadTable"
import RatesheetRow from "./RatesheetRow"

const CostTable: FC = () => {
  const hideOverriddenNetRatesheet = useSelector(isOverriddenNetRatesheetHidden)
  const hideCalculatedNetRatesheet = useSelector(isCalculatedNetRatesheetHidden)
  const showSelectionButton = !hideOverriddenNetRatesheet && !hideCalculatedNetRatesheet
  const { net_ratesheet } = useSelector(getFieldSettings(ReservedFields.NET_RATESHEET))

  const totalCostRowLabel = showSelectionButton ? "Overridden Total" : "Total Cost"

  return (
    <>
      {!hideCalculatedNetRatesheet && (
        <div className="d-flex flex-column gap-3">
          <StopComponentTable />
          <GeneralComponentTable fieldName={ReservedFields.VENUE_COMPONENTS} />
          <GeneralComponentTable fieldName={ReservedFields.LOGISTICAL_COMPONENTS} />
          <GeneralComponentTable fieldName={ReservedFields.REFRESHMENT_COMPONENTS} />
          <GeneralComponentTable fieldName={ReservedFields.RESTAURANT_COMPONENTS} />
          <GeneralComponentTable fieldName={ReservedFields.ENTERTAINMENT_COMPONENTS} />
          <GeneralComponentTable fieldName={ReservedFields.GUIDE_COMPONENTS} />
          <RatesheetRow fieldName={ReservedFields.HEADSET_FEE_RATESHEET} rowLabel="Headset Fee" />
          <OverheadTable />
          <RatesheetRow fieldName={ReservedFields.OPERATOR_FEE_RATESHEET} rowLabel="Operator Fee" />
          <div className="py-3" />
          <RatesheetRow
            fieldName={ReservedFields.CALCULATED_NET_RATESHEET}
            rowLabel="Calculated Total"
            showRateSelectionButton={showSelectionButton}
          />
        </div>
      )}
      {!hideOverriddenNetRatesheet && (
        <RatesheetRow
          fieldName={ReservedFields.OVERRIDDEN_NET_RATESHEET}
          rowLabel={totalCostRowLabel}
          showRateSelectionButton={showSelectionButton}
          editable={net_ratesheet.writeable}
        />
      )}
    </>
  )
}

export default CostTable
