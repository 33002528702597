import React, { useState } from "react"
import { Card, Col } from "react-bootstrap"
import CruiseLineOnly from "@/auth_descriptor/CruiseLineOnly"
import OperatorOnly from "@/auth_descriptor/OperatorOnly"
import { format, parseISO } from "date-fns"
import { ChatButton } from "@/components/chat"
import ViewUpload from "./ViewUpload"
import StatusIcon from "../../StatusIcon"

const ExpiredUploads = ({ expiredUploads }) => {
  const [selectedCertificateId, setSelectedCertificateId] = useState(null)

  const toggleFormFields = (certificateId) => {
    setSelectedCertificateId((prevSelected) =>
      prevSelected === certificateId ? null : certificateId
    )
  }

  const expiredUploadElements = expiredUploads.map((upload) => {
    const { fileSrc } = upload.certificate
    return (
      <div className="col-sm-5 mt-2" key={upload.certificate.certificateId}>
        <Card>
          <h5 className="ms-4 mt-2 text-muted">Archived Insurance Upload</h5>
          <OperatorOnly>
            <div className="ms-4 mt-2 mb-4 text-muted">
              <StatusIcon status="archived" label="archived" />
              This insurance upload expired on{" "}
              {format(parseISO(upload.certificate.expirationDate), "dd MMMM yyyy")}
              <div
                key={`fas fa-caret-down-${upload.certificate.certificateId}`}
                className="d-inline-block ms-2"
                onClick={() => toggleFormFields(upload.certificate.certificateId)}
              >
                <span className="fas fa-caret-down" title="view" />
              </div>
            </div>

            {selectedCertificateId === upload.certificate.certificateId && (
              <div className="ms-4 mt-2 mb-4">
                <ViewUpload form={upload.certificate} fileSrc={fileSrc} />
              </div>
            )}
          </OperatorOnly>

          <CruiseLineOnly>
            <>
              <div
                className="ms-4 mt-2 mb-4 text-muted cursor-pointer"
                onClick={() => toggleFormFields(upload.certificate.certificateId)}
              >
                <StatusIcon status="archived" label="archived" />
                This insurance upload expired on{" "}
                {format(parseISO(upload.certificate.expirationDate), "dd MMMM yyyy")}
                <div key="fas fa-caret-down" className="d-inline-block ms-2">
                  <span className="fas fa-caret-down" title="view" />
                </div>
              </div>

              {selectedCertificateId === upload.certificate.certificateId && (
                <div className="ms-4 mt-2 mb-4">
                  <ViewUpload form={upload.certificate} fileSrc={fileSrc} />
                </div>
              )}
            </>
          </CruiseLineOnly>
          <Col className="position-absolute end-0 me-3 mt-2">
            {upload.chat && <ChatButton chat={upload.chat} key={upload.chat.id} buttonStyle />}
          </Col>
        </Card>
      </div>
    )
  })

  return <div>{expiredUploadElements}</div>
}

export default ExpiredUploads
