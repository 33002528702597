import React, { useContext, useState, useEffect } from "react"

import { Button } from "react-bootstrap"
import Actions from "./actions"

import ChatBox from "./ChatBox"

import ChatContext, { Provider } from "./ChatContext"

const UnreadChatDot = ({ className = "" }) => <div className={className} />

const ChatButton = ({ className = "", buttonStyle = false }): JSX.Element => {
  const { conversation, updateConversation } = useContext(ChatContext)

  const defaultClassName = "fs-5"

  const [isVisible, setIsVisible] = useState(false)

  useEffect(() => {
    if (isVisible && conversation.isUnread && conversation.id) {
      Actions.markAsRead({ conversationId: conversation.id }).then(() =>
        updateConversation({ isUnread: false, isRead: true })
      )
    }
  }, [isVisible])

  return (
    <div>
      <a
        className={className || defaultClassName}
        role="button"
        onClick={() => setIsVisible(!isVisible)}
      >
        {!buttonStyle && (
          <span style={{ position: "relative", width: "40px" }}>
            <i key="comment" className="fas fa-comment" />
            {conversation.isUnread && <UnreadChatDot className="unread-chat-dot" />}
          </span>
        )}
        {buttonStyle && (
          <>
            <Button>
              <i key="comment" className="fas fa-comment me-2" />
              Chat
            </Button>
            {conversation.isUnread && <UnreadChatDot className="unread-chat-dot ms-2 mt-1" />}
          </>
        )}
      </a>
      <ChatBox isVisible={isVisible} onClose={() => setIsVisible(false)} />
    </div>
  )
}

const Wrapper = ({ chat, className = "", buttonStyle = false }): JSX.Element => (
  <Provider conversation={chat}>
    <ChatButton className={className} buttonStyle={buttonStyle} />
  </Provider>
)

export default Wrapper
