import React from "react"
import { Modal, Button } from "react-bootstrap"

const UnsavedChangesModal = ({ show, onHide, onConfirm }) => (
  <Modal show={show} onHide={onHide} centered>
    <Modal.Header closeButton>
      <Modal.Title>Unsaved Changes</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <p>You have unsaved changes. Do you want to discard them?</p>
    </Modal.Body>
    <Modal.Footer>
      <Button variant="secondary" onClick={onHide}>
        Cancel
      </Button>
      <Button
        variant="danger"
        onClick={() => {
          onConfirm()
          onHide()
        }}
      >
        Discard Changes
      </Button>
    </Modal.Footer>
  </Modal>
)

export default UnsavedChangesModal
