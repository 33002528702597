import React, { useState } from "react"
import { Table, Button } from "react-bootstrap"
import CruiseLineOnly from "@/auth_descriptor/CruiseLineOnly"
import ProcurementOnly from "@/auth_descriptor/ProcurementOnly"
import OperatorOnly from "@/auth_descriptor/OperatorOnly"
import _ from "lodash"
import routes from "@/routes"
import { getStatus } from "@/insurance/features/helpers"
import { useSelector } from "react-redux"
import CertificateTable from "./CertificateTable"
import { getStatusLabels } from "../selectors"
import StatusIcon from "../../StatusIcon"

const InsuranceTableRow = ({ company }) => {
  const [expanded, setExpanded] = useState(false)

  const toggleRows = () => {
    setExpanded(!expanded)
  }
  const statusLabels = useSelector(getStatusLabels)

  const operatorData = (
    <tr key={company.name} className="cursor-pointer">
      <td onClick={toggleRows}>
        <span>{company.name}</span>
      </td>
      <CruiseLineOnly>
        <td onClick={toggleRows}>
          {_.map(company.regions, (region) => (
            <>
              <span key={region.name}>{region.name}</span>
              <br />
            </>
          ))}
        </td>
      </CruiseLineOnly>
      {_.map(company.profile.insuranceCategories, (insuranceCategory) => (
        <th onClick={toggleRows} key={insuranceCategory.categoryName} scope="col">
          <StatusIcon
            status={getStatus(insuranceCategory.insuranceStatus)}
            label={_.startCase(statusLabels[insuranceCategory.insuranceStatus])}
          />
          <span className="ms-2">
            {getStatus(insuranceCategory.insuranceStatus) != "unrequired" &&
              _.startCase(statusLabels[getStatus(insuranceCategory.insuranceStatus)])}
          </span>
        </th>
      ))}
      <td>
        <ProcurementOnly>
          <Button
            href={routes.operator_profile_insurances_path({
              operator_profile_id: company.profile.id,
              _options: true,
            })}
          >
            Review
          </Button>
        </ProcurementOnly>
        <OperatorOnly>
          <Button
            href={routes.operator_profile_insurances_path({
              operator_profile_id: company.profile.id,
              _options: true,
            })}
          >
            View Profile
          </Button>
        </OperatorOnly>
      </td>
    </tr>
  )

  if (!expanded) {
    return operatorData
  }

  return (
    <>
      {operatorData}
      <tr>
        <td colSpan={8} className="p-0">
          <CertificateTable insuranceUploads={company.profile.insuranceUploads} />
        </td>
      </tr>
    </>
  )
}

const InsuranceTableBody = ({ companies }) =>
  _.isEmpty(companies) ? (
    <tr>
      <td colSpan={4}>No companies found</td>
    </tr>
  ) : (
    companies.map((company) => <InsuranceTableRow company={company} key={company.name} />)
  )

const InsuranceTable = ({ profiles }) => (
  <Table responsive size="sm" className="align-items-center mb-5">
    <thead className="thead-light">
      <tr>
        <CruiseLineOnly>
          <th scope="col">Tour Operator</th>
        </CruiseLineOnly>
        <OperatorOnly>
          <th scope="col">Cruise Line</th>
        </OperatorOnly>
        <CruiseLineOnly>
          <th scope="col">Region</th>
        </CruiseLineOnly>
        {_.isEmpty(profiles) ? (
          <th />
        ) : (
          _.map(profiles[0].profile.insuranceCategories, (category) => (
            <th key={category.categoryName} scope="col">
              {category.categoryShortName}
            </th>
          ))
        )}
        <th />
      </tr>
    </thead>
    <tbody>
      <InsuranceTableBody companies={profiles} />
    </tbody>
  </Table>
)

export default InsuranceTable
