import React from "react"
import _ from "lodash"

const PortLink = ({ visit }): JSX.Element => {
  if (_.isNil(visit.port.id)) {
    return <>At Sea</>
  }

  return <>{visit.port.name}</>
}

export default PortLink
