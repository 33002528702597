import React, { FC } from "react"
import { Table } from "react-bootstrap"
import { useSelector } from "react-redux"

import { getVisitsByPort } from "@/itinerary_dashboard/selectors"
import { rowStyle } from "@/itinerary_dashboard/helpers"
import OperatorOnly from "@/auth_descriptor/OperatorOnly"

import VisitTimeDisplay from "./VisitTimeDisplay"

const Row = ({ visit }) => {
  const row = (
    <tr style={rowStyle(visit.date)}>
      <td>{visit.formattedDate}</td>
      <td>{visit.port.name}</td>
      <td>{visit.cruiseCompany.name}</td>
      <td>{visit.pier}</td>
      <td>{visit.berthingMethod}</td>
      <td>{visit.ship.name}</td>
      <td>{visit.itinerary.name}</td>
      <td>{visit.callType}</td>
      <td>{visit.charter ? "Yes" : "No"}</td>
      <td>
        <VisitTimeDisplay time={visit.arrivalTime} />
      </td>
      <td>
        <VisitTimeDisplay time={visit.departureTime} />
      </td>
    </tr>
  )

  if (visit.operatorOnly) {
    return <OperatorOnly>{row}</OperatorOnly>
  }

  return row
}

const PortTable: FC = () => {
  const visits = useSelector(getVisitsByPort)

  return (
    <Table responsive>
      <thead className="thead-light">
        <tr>
          <th scope="col">Date</th>
          <th scope="col">Port</th>
          <th scope="col">Cruise Line</th>
          <th scope="col">Pier</th>
          <th scope="col">Berthing Method</th>
          <th scope="col">Ship</th>
          <th scope="col">Itinerary</th>
          <th scope="col">Call Type</th>
          <th scope="col">Charter</th>
          <th scope="col">Arrival Time</th>
          <th scope="col">Departure Time</th>
        </tr>
      </thead>
      <tbody>
        {visits.map((visit, index) => (
          <Row key={`${visit.date}-${visit.ship.id}-${index}`} visit={visit} />
        ))}
      </tbody>
    </Table>
  )
}

export default PortTable
