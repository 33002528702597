import React, { FC } from "react"
import { Button } from "react-bootstrap"
import { useDispatch, useSelector } from "react-redux"

import { toggleProposedDetails } from "@/onboarding/features/product/Slice"
import { getFeatures, getProduct, getProductFilters } from "@/onboarding/features/product/selectors"
import { isBlank } from "@/utils"

const ToggleProposedDetailsButton: FC = () => {
  const dispatch = useDispatch()
  const features = useSelector(getFeatures)
  const { detailsConfirmationId } = useSelector(getProductFilters)
  const { detailsConfirmation } = useSelector(getProduct)

  if (!features.general.product.toggleProposedChanges) {
    return null
  }

  let text

  if (isBlank(detailsConfirmationId)) {
    if (detailsConfirmation.status === "pending") {
      text = "Propose Changes"
    } else {
      text = "View Proposed Changes"
    }
  } else {
    text = "View Current Details"
  }

  return (
    <div>
      <Button variant="outline-primary" onClick={() => dispatch(toggleProposedDetails({}))}>
        {text}
      </Button>
    </div>
  )
}

export default ToggleProposedDetailsButton
