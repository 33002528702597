import React from "react"
import { Col, Row } from "react-bootstrap"
import { useSelector } from "react-redux"

import {
  getProductField,
  getSectionFieldNames,
  getSettings,
} from "@/onboarding/features/product/selectors"
import TextDisplay from "@/onboarding/features/product/components/shared/TextDisplay"
import { ReservedFields } from "@/onboarding/features/product/models/Product"

const DisplayValue = ({
  name,
  label,
  requiredField = false,
  incompleteField = false,
}): JSX.Element => {
  const { value } = useSelector(getProductField(name))

  return (
    <div className="d-flex flex-column gap-1">
      <span>
        <b>
          {label}
          {requiredField && incompleteField && (
            <span
              data-toggle="tooltip"
              data-placement="top"
              title="This is an incomplete required field"
              className="ms-1"
              style={{ color: "red" }}
            >
              *
            </span>
          )}
        </b>
      </span>
      <TextDisplay value={value} />
    </div>
  )
}

const Content = (): JSX.Element => {
  const fieldNames = useSelector(getSectionFieldNames.description)
  const fieldsSettings = useSelector(getSettings(...fieldNames))

  return (
    <div className="d-flex flex-column gap-3">
      <DisplayValue
        name={ReservedFields.OVERVIEW}
        label="Overview"
        requiredField={fieldsSettings[ReservedFields.OVERVIEW].required}
        incompleteField={fieldsSettings[ReservedFields.OVERVIEW].incomplete}
      />
      <Row>
        <Col>
          <DisplayValue
            name={ReservedFields.HIGHLIGHTS}
            label="Highlights"
            requiredField={fieldsSettings[ReservedFields.HIGHLIGHTS].required}
            incompleteField={fieldsSettings[ReservedFields.HIGHLIGHTS].incomplete}
          />
        </Col>
        <Col>
          <DisplayValue
            name={ReservedFields.IMPORTANT_NOTES}
            label="Important Notes"
            requiredField={fieldsSettings[ReservedFields.IMPORTANT_NOTES].required}
            incompleteField={fieldsSettings[ReservedFields.IMPORTANT_NOTES].incomplete}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <DisplayValue
            name={ReservedFields.WHAT_TO_BRING}
            label="What to Bring"
            requiredField={fieldsSettings[ReservedFields.WHAT_TO_BRING].required}
            incompleteField={fieldsSettings[ReservedFields.WHAT_TO_BRING].incomplete}
          />
        </Col>
        <Col>
          <DisplayValue
            name={ReservedFields.WHAT_TO_WEAR}
            label="What to Wear"
            requiredField={fieldsSettings[ReservedFields.WHAT_TO_WEAR].required}
            incompleteField={fieldsSettings[ReservedFields.WHAT_TO_WEAR].incomplete}
          />
        </Col>
      </Row>
    </div>
  )
}

export default Content
