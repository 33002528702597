import React, { FC } from "react"

import { useSelector } from "react-redux"
import _ from "lodash"
import SectionHolder from "./shared/SectionHolder"
import Content from "./tour_details/Content"
import Edit from "./tour_details/Edit"
import { getSectionFieldNames, countIncompleteRequiredFields, getSectionChat } from "../selectors"

const TourDetails: FC = () => {
  const fieldNames = useSelector(getSectionFieldNames.tourDetails)
  const sectionChat = useSelector(getSectionChat(_.snakeCase("tourDetails")))
  const incompleteFieldCount = useSelector(countIncompleteRequiredFields(...fieldNames))

  // TODO(product): Fix bug: For numeric fields in the Tour Details section, if the user deletes the default value "0" (leaving the field empty), the form submits an empty string ("") instead of a valid numeric value, causing the submission to fail.
  return (
    <SectionHolder
      title="Tour Details"
      editComponent={Edit}
      editMethod="inline"
      incompleteRequiredFieldCount={incompleteFieldCount}
      chat={sectionChat}
    >
      <Content />
    </SectionHolder>
  )
}
export default TourDetails
