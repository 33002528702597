import React from "react"
import { Button, Col, Form, Row } from "react-bootstrap"
import { useDispatch, useSelector } from "react-redux"
import { Formik } from "formik"

import {
  getFieldValues,
  getSectionFieldNames,
  getFieldSettings,
} from "@/onboarding/features/product/selectors"
import { updateFields } from "@/onboarding/features/product/Slice"
import FormikBootstrap from "@/components/formik-bootstrap"
import { ReservedFields } from "@/onboarding/features/product/models/Product"
import LoadingSpinner from "@/components/LoadingSpinner"
import { useProductSubmitting } from "@/onboarding/features/product/helpers"
import FormikTextList from "@/onboarding/features/product/components/shared/FormikTextList"

const Edit = ({ setEditing }): JSX.Element => {
  const fieldNames = useSelector(getSectionFieldNames.description)
  const initialValues = useSelector(getFieldValues(...fieldNames))
  const fieldsSettings = useSelector(getFieldSettings(...fieldNames))

  const dispatch = useDispatch()
  const [isSubmitting, setSubmitting] = useProductSubmitting()

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      onSubmit={(values) => {
        setSubmitting(true)
        dispatch(updateFields({ values }))
      }}
    >
      {({ handleSubmit }) => (
        <Form className="d-flex flex-column gap-3" onSubmit={handleSubmit}>
          <FormikBootstrap.TextAreaInput
            rows={5}
            name={ReservedFields.OVERVIEW}
            label="Overview"
            readOnly={!fieldsSettings[ReservedFields.OVERVIEW].writeable}
            showLockedIcon={!fieldsSettings[ReservedFields.OVERVIEW].writeable}
            showRequired={fieldsSettings[ReservedFields.OVERVIEW].required}
          />
          <Row>
            <Col md={6}>
              <FormikTextList
                name={ReservedFields.HIGHLIGHTS}
                label="Highlights"
                readOnly={!fieldsSettings[ReservedFields.HIGHLIGHTS].writeable}
                showLockedIcon={!fieldsSettings[ReservedFields.HIGHLIGHTS].writeable}
                showRequired={fieldsSettings[ReservedFields.HIGHLIGHTS].required}
              />
            </Col>
            <Col md={6}>
              <FormikTextList
                name={ReservedFields.IMPORTANT_NOTES}
                label="Important Notes"
                readOnly={!fieldsSettings[ReservedFields.IMPORTANT_NOTES].writeable}
                showLockedIcon={!fieldsSettings[ReservedFields.IMPORTANT_NOTES].writeable}
                showRequired={fieldsSettings[ReservedFields.IMPORTANT_NOTES].required}
              />
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <FormikTextList
                name={ReservedFields.WHAT_TO_BRING}
                label="What to Bring"
                readOnly={!fieldsSettings[ReservedFields.WHAT_TO_BRING].writeable}
                showLockedIcon={!fieldsSettings[ReservedFields.WHAT_TO_BRING].writeable}
                showRequired={fieldsSettings[ReservedFields.WHAT_TO_BRING].required}
              />
            </Col>
            <Col md={6}>
              <FormikTextList
                name={ReservedFields.WHAT_TO_WEAR}
                label="What to Wear"
                readOnly={!fieldsSettings[ReservedFields.WHAT_TO_WEAR].writeable}
                showLockedIcon={!fieldsSettings[ReservedFields.WHAT_TO_WEAR].writeable}
                showRequired={fieldsSettings[ReservedFields.WHAT_TO_WEAR].required}
              />
            </Col>
          </Row>
          <div className="d-flex gap-2">
            <Button size="sm" type="submit" disabled={isSubmitting}>
              <i className="fas fa-save me-2" />
              Save
            </Button>
            <Button
              size="sm"
              variant="outline-danger"
              onClick={() => setEditing(false)}
              disabled={isSubmitting}
            >
              <i className="fas fa-times me-2" />
              Cancel
            </Button>
            {isSubmitting && <LoadingSpinner />}
          </div>
        </Form>
      )}
    </Formik>
  )
}

export default Edit
