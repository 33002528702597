import React from "react"
import { Card, Row, Col } from "react-bootstrap"
import { useSelector } from "react-redux"
import LoadingSpinner from "@/components/LoadingSpinner"
import CruiseLineOnly from "@/auth_descriptor/CruiseLineOnly"
import OperatorOnly from "@/auth_descriptor/OperatorOnly"
import { InfoCircle } from "@/components/Icons"
import { getInsurance } from "../selectors"
import MultiSelector from "./MultiSelector"
import InsuranceTable from "./InsuranceTable"
import OverviewModalLink from "./OverviewModalLink"

const Dashboard = ({ profiles }): JSX.Element => {
  const { isTableLoading } = useSelector(getInsurance)
  return (
    <Card>
      <Card.Header className="d-inline-flex flex-column gap-4">
        <Row>
          <Col md={8} className="d-inline-flex align-items-center gap-2">
            <h1 className="h4 mb-0">Insurance Dashboard</h1>
            {isTableLoading ? <LoadingSpinner className="align-self-center ms-2" /> : <div />}
          </Col>
          <OperatorOnly>
            <div className="d-flex align-items-center mt-2">
              <span>Manage Your Insurance</span>
              <InfoCircle
                className="ms-2 cursor-pointer"
                data-toggle="tooltip"
                data-placement="top"
                title="Track your insurance statuses with the cruise lines you work with. Navigate to your profiles with each cruise line and view uploaded certificates."
              />
            </div>
          </OperatorOnly>
          <CruiseLineOnly>
            <Col>
              <div className="text-right">
                <OverviewModalLink />
              </div>
            </Col>
          </CruiseLineOnly>
        </Row>
        <Row>
          <OperatorOnly>
            <Col md={2}>
              <MultiSelector name="cruiseLines" />
            </Col>
          </OperatorOnly>
          <CruiseLineOnly>
            <Col md={2}>
              <MultiSelector name="operators" />
            </Col>
            <Col md={2}>
              <MultiSelector name="ports" />
            </Col>
            <Col md={2}>
              <MultiSelector name="regions" />
            </Col>
            <Col md={2}>
              <MultiSelector name="countries" />
            </Col>
            <Col md={2}>
              <MultiSelector name="operatorStatus" />
            </Col>
          </CruiseLineOnly>
          <Col md={2}>
            <MultiSelector name="insuranceStatus" />
          </Col>
        </Row>
      </Card.Header>

      <InsuranceTable profiles={profiles} />
    </Card>
  )
}

export default Dashboard
