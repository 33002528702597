import { createSlice } from "@reduxjs/toolkit"
import _ from "lodash"

import * as AsyncField from "@/helpers/AsyncField"
import Api from "@/api"
import { newAxiosErrorHandler, newSuccessHandler } from "@/osn"
import * as Field from "@/helpers/Field"
import { isBlank } from "@/utils"

import { getFieldUpdateContext, getForm, getProductField, getProductId } from "./selectors"

const createProductAPIThunk = (apiFn) =>
  AsyncField.createLoadThunk(
    `product/${apiFn.name}`,
    () => "_view",
    async ({}, thunkAPI) => {
      const id = getProductId(thunkAPI.getState())

      const response = await apiFn(id, false)
        .then(newSuccessHandler(thunkAPI.dispatch))
        .catch(newAxiosErrorHandler(thunkAPI.dispatch))

      return {
        value: response.data.data.view,
      }
    }
  )

export const openProductForBidding = createProductAPIThunk(
  Api.onboardingProduct.openProductForBidding
)
export const closeProductForBidding = createProductAPIThunk(
  Api.onboardingProduct.closeProductForBidding
)
export const resetProduct = createProductAPIThunk(Api.onboardingProduct.resetProduct)
export const activateProduct = createProductAPIThunk(Api.onboardingProduct.activateProduct)

const createBidAPIThunk = (apiFn) =>
  AsyncField.createLoadThunk(
    `product/${apiFn.name}`,
    () => "_view",
    async ({ bidId }, thunkAPI) => {
      const id = getProductId(thunkAPI.getState())

      const response = await apiFn(id, bidId)
        .then(newSuccessHandler(thunkAPI.dispatch))
        .catch(newAxiosErrorHandler(thunkAPI.dispatch))

      return {
        value: response.data.data.view,
      }
    }
  )

export const submitBid = createBidAPIThunk(Api.onboardingProduct.submitBid)
export const acceptBid = createBidAPIThunk(Api.onboardingProduct.acceptBid)
export const rejectBid = createBidAPIThunk(Api.onboardingProduct.rejectBid)
export const pendBid = createBidAPIThunk(Api.onboardingProduct.pendBid)
export const removeBid = createBidAPIThunk(Api.onboardingProduct.removeBid)

export const updateFields = AsyncField.createLoadThunk(
  "product/updateField",
  () => "_view",
  async ({ values }, thunkAPI) => {
    const context = getFieldUpdateContext(thunkAPI.getState())
    const form = getForm(thunkAPI.getState())

    const fields = _.reduce(
      values,
      (result, value, key) =>
        _.concat(result, {
          name: key,
          value,
        }),
      []
    )

    const response = await Api.onboardingProduct
      .updateProductFields(context.id, fields, context, form)
      .then(newSuccessHandler(thunkAPI.dispatch))
      .catch(newAxiosErrorHandler(thunkAPI.dispatch))

    return {
      value: response.data.data.view,
    }
  }
)

// TODO This can be removed soon in favour of +filter+
export const fetchProduct = AsyncField.createLoadThunk(
  "product/fetchProduct",
  () => "_view",
  async (params, thunkAPI) => {
    const response = await Api.onboardingProduct
      .fetchProduct(params)
      .catch(newAxiosErrorHandler(thunkAPI.dispatch))

    return {
      value: response.data.data.view,
    }
  }
)

export const filter = AsyncField.createLoadThunk(
  "product/filter",
  () => "_view",
  async (form, thunkAPI) => {
    const id = getProductId(thunkAPI.getState())

    const response = await Api.onboardingProduct
      .filter(id, form)
      .catch(newAxiosErrorHandler(thunkAPI.dispatch))

    return {
      value: response.data.data.view,
    }
  }
)

export const submitComment = AsyncField.createLoadThunk(
  "product/submitComment",
  () => "_view",
  async ({ message, sectionName }, thunkAPI) => {
    const id = getProductId(thunkAPI.getState())
    const context = getFieldUpdateContext(thunkAPI.getState())

    const response = await Api.onboardingProduct
      .submitComment(id, context, message, sectionName)
      .then(newSuccessHandler(thunkAPI.dispatch))
      .catch(newAxiosErrorHandler(thunkAPI.dispatch))

    return {
      value: response.data.data.view,
    }
  }
)

export const appendFieldListItem = AsyncField.createLoadThunk(
  "product/appendFieldListItem",
  () => "_view",
  async ({ fieldName, value }, thunkAPI) => {
    const field = getProductField(fieldName)(thunkAPI.getState())
    const context = getFieldUpdateContext(thunkAPI.getState())
    const form = getForm(thunkAPI.getState())

    const updatedValue = [Field.appendValueElement(field, value)]

    const response = await Api.onboardingProduct
      .updateProductFields(context.id, updatedValue, context, form)
      .then(newSuccessHandler(thunkAPI.dispatch, "Item added"))
      .catch(newAxiosErrorHandler(thunkAPI.dispatch))

    return {
      value: response.data.data.view,
    }
  }
)

export const removeFieldListItem = AsyncField.createLoadThunk(
  "product/removeFieldListItem",
  () => "_view",
  async ({ fieldName, index }, thunkAPI) => {
    const field = getProductField(fieldName)(thunkAPI.getState())
    const context = getFieldUpdateContext(thunkAPI.getState())
    const form = getForm(thunkAPI.getState())

    const updatedValue = [Field.removeValueElement(field, index)]

    const response = await Api.onboardingProduct
      .updateProductFields(context.id, updatedValue, context, form)
      .then(newSuccessHandler(thunkAPI.dispatch, "Item removed"))
      .catch(newAxiosErrorHandler(thunkAPI.dispatch))

    return {
      value: response.data.data.view,
    }
  }
)

export const updateFieldListItem = AsyncField.createLoadThunk(
  "product/updateFieldListItem",
  () => "_view",
  async ({ fieldName, index, value }, thunkAPI) => {
    const field = getProductField(fieldName)(thunkAPI.getState())
    const context = getFieldUpdateContext(thunkAPI.getState())
    const form = getForm(thunkAPI.getState())

    const updatedValue = [Field.updateValueElement(field, index, value)]

    const response = await Api.onboardingProduct
      .updateProductFields(context.id, updatedValue, context, form)
      .then(newSuccessHandler(thunkAPI.dispatch, "Item updated"))
      .catch(newAxiosErrorHandler(thunkAPI.dispatch))

    return {
      value: response.data.data.view,
    }
  }
)

export const proposeProduct = AsyncField.createLoadThunk(
  "product/propose",
  () => "_view",
  async ({ recipientId }, thunkAPI) => {
    const id = getProductId(thunkAPI.getState())

    const response = await Api.onboardingProduct
      .proposeProduct(id, recipientId)
      .then(newSuccessHandler(thunkAPI.dispatch))
      .catch(newAxiosErrorHandler(thunkAPI.dispatch))

    return {
      value: response.data.data.view,
    }
  }
)

export const addContractPeriod = AsyncField.createLoadThunk(
  "product/addContractPeriod",
  () => "_view",
  async ({ startDate }, thunkAPI) => {
    const id = getProductId(thunkAPI.getState())

    const response = await Api.onboardingProduct
      .addContractPeriod(id, startDate)
      .then(newSuccessHandler(thunkAPI.dispatch))
      .catch(newAxiosErrorHandler(thunkAPI.dispatch))

    return {
      value: response.data.data.view,
    }
  }
)

export const submitFieldSettings = AsyncField.createLoadThunk(
  "product/submitFieldSettings",
  () => "_view",
  async (settings, thunkAPI) => {
    const id = getProductId(thunkAPI.getState())

    const response = await Api.onboardingProduct
      .submitFieldSettings(id, settings)
      .then(newSuccessHandler(thunkAPI.dispatch))
      .catch(newAxiosErrorHandler(thunkAPI.dispatch))

    return {
      value: response.data.data.view,
    }
  }
)

export const submitMainDetails = AsyncField.createLoadThunk(
  "product/submitMainDetails",
  () => "_view",
  async (values, thunkAPI) => {
    const id = getProductId(thunkAPI.getState())

    const response = await Api.onboardingProduct
      .submitMainDetails(id, values)
      .then(newSuccessHandler(thunkAPI.dispatch))
      .catch(newAxiosErrorHandler(thunkAPI.dispatch))

    return {
      value: response.data.data.view,
    }
  }
)

export const toggleProposedDetails = AsyncField.createLoadThunk(
  "product/toggleProposedDetails",
  () => "_view",
  async ({}, thunkAPI) => {
    const id = getProductId(thunkAPI.getState())
    const form = _.cloneDeep(getForm(thunkAPI.getState()))

    if (isBlank(form.detailsConfirmationId.value)) {
      form.detailsConfirmationId.value = form.detailsConfirmationId.options[1].value
    } else {
      form.detailsConfirmationId.value = form.detailsConfirmationId.options[0].value
    }

    const response = await Api.onboardingProduct
      .filter(id, form)
      .catch(newAxiosErrorHandler(thunkAPI.dispatch))

    return {
      value: response.data.data.view,
    }
  }
)

const Slice = createSlice({
  name: "product",
  initialState: {
    initialized: false,
    productId: "",
    authDescriptor: {},
    formsEditable: false,
    formsExpanded: true,
    _view: {},
  },
  reducers: {
    initialize: (state, { payload: { view, authDescriptor } }) => ({
      ...state,
      initialized: true,
      productId: view.productId,
      authDescriptor,
      formsEditable: view.product.formsEditableDefault,
      _view: AsyncField.createField({
        defaultValue: view,
        state: AsyncField.LoadState.Stale,
      }),
    }),
    toggleFormsEditable: (state) => ({
      ...state,
      formsEditable: !state.formsEditable,
    }),
    toggleFormsExpanded: (state) => ({
      ...state,
      formsExpanded: !state.formsExpanded,
    }),
  },
  extraReducers: {
    ...updateFields.reducers,
    ...openProductForBidding.reducers,
    ...closeProductForBidding.reducers,
    ...appendFieldListItem.reducers,
    ...removeFieldListItem.reducers,
    ...updateFieldListItem.reducers,
    ...submitBid.reducers,
    ...acceptBid.reducers,
    ...rejectBid.reducers,
    ...pendBid.reducers,
    ...removeBid.reducers,
    ...resetProduct.reducers,
    ...fetchProduct.reducers,
    ...activateProduct.reducers,
    ...submitComment.reducers,
    ...proposeProduct.reducers,
    ...submitFieldSettings.reducers,
    ...submitMainDetails.reducers,
    ...addContractPeriod.reducers,
    ...filter.reducers,
    ...toggleProposedDetails.reducers,
  },
})

export const { initialize, toggleFormsEditable, toggleFormsExpanded } = Slice.actions

export default Slice
