import React from "react"
import { Form, Col, Row, Card, Button } from "react-bootstrap"
import _ from "lodash"
import ProcurementOnly from "@/auth_descriptor/ProcurementOnly"
import OperatorOnly from "@/auth_descriptor/OperatorOnly"
import { useDispatch, useSelector } from "react-redux"
import { InfoCircle } from "@/components/Icons"
import ContractedCheckBox from "./ContractedCheckBox"
import { submitContractedIds } from "../Slice"
import { getProfile } from "../selectors"

const TransportationCategories = ({ props }) => {
  const dispatch = useDispatch()
  const { pendingContractedIds } = useSelector(getProfile)

  const onSubmit = () => {
    dispatch(submitContractedIds({ contractedIds: pendingContractedIds }))
  }

  return (
    <>
      <div className="d-flex align-items-center">
        <h5>Transportation Categories</h5>
        <InfoCircle
          className="ms-2 mt-n2 cursor-pointer"
          data-toggle="tooltip"
          data-placement="top"
          title="Select the relevant transport types. Choose all that apply to your tours. This will update your insurance requirements."
        />
      </div>
      {_.map(
        props.profile.insuranceCategories,
        (insuranceTypeCategory) =>
          insuranceTypeCategory.categoryName != "General Liability" && (
            <Card key={insuranceTypeCategory.categoryId}>
              <h5 className="ms-4 mt-2">{insuranceTypeCategory.categoryName}</h5>
              <OperatorOnly>
                <span className="ms-4 mb-3">{`Please select all forms of ${insuranceTypeCategory.categoryName} included in the portfolio of your contracted tours`}</span>
              </OperatorOnly>
              <ProcurementOnly>
                <span className="ms-4 mb-3">{`Forms of ${insuranceTypeCategory.categoryName} included in the portfolio of their contracted tours`}</span>
              </ProcurementOnly>
              <Row className="h-150 ms-3">
                {_.map(insuranceTypeCategory.vehicleTypes, (vehicleType) => (
                  <Col key={vehicleType.id} className="col-md-3 col-lg-4 G">
                    <Form.Group>
                      <ContractedCheckBox vehicleType={vehicleType} />
                    </Form.Group>
                  </Col>
                ))}
              </Row>
            </Card>
          )
      )}
      <OperatorOnly>
        <Col className="col-md-3 col-lg-4 G">
          <Button className="btn-primary position-absolute end-0 me-3  z-1" onClick={onSubmit}>
            Submit
          </Button>
        </Col>
      </OperatorOnly>
    </>
  )
}

export default TransportationCategories
