import React, { FC } from "react"
import { useSelector } from "react-redux"

import { getFeatures, getProduct, getProductFilters } from "@/onboarding/features/product/selectors"
import routes from "@/routes"
import ButtonLink from "@/components/ButtonLink"

const RejectContractPeriodDetailsButton: FC = () => {
  const {
    season: { id: seasonId },
  } = useSelector(getProduct)
  const { contractPeriodId } = useSelector(getProductFilters)
  const features = useSelector(getFeatures)

  if (!features.general.product.rejectContractPeriodDetails) {
    return null
  }

  return (
    <div>
      <ButtonLink
        label="Submit Proposed Tour Changes"
        button={{ variant: "success" }}
        remote
        params={{
          refresh_page: true,
          season_id: seasonId,
          contract_period_id: contractPeriodId,
        }}
        href={routes.new_onboarding_bidding_reject_contract_period_detail_path()}
      />
    </div>
  )
}

export default RejectContractPeriodDetailsButton
