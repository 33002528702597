import React, { FC } from "react"
import { Col, Row, Table } from "react-bootstrap"

import FormikBootstrap from "@/components/formik-bootstrap"
import TextDisplay from "@/onboarding/features/product/components/shared/TextDisplay"
import Pricing from "@/pricing"
import ReferencedValueSelect from "@/onboarding/features/product/components/shared/ReferencedValueSelect"
import { ReferencedValueTypes } from "@/onboarding/features/product/models/Product"
import RateInputs from "../../shared/RateInputs"

const WriteView: FC = () => (
  <Row className="mb-2">
    <Col md={8}>
      <h5>Edit Details</h5>
      <Row className="mb-2">
        <Col md={5}>
          <FormikBootstrap.TextInput name="name" label="Name" />
        </Col>
        <Col md={5}>
          <FormikBootstrap.TextInput name="description" label="Description" />
        </Col>
      </Row>
      <Row className="mb-2">
        <Col md={5}>
          <ReferencedValueSelect
            name="refreshmentType"
            type={ReferencedValueTypes.REFRESHMENT_TYPE}
            label="Refreshment Type"
          />
        </Col>
      </Row>
    </Col>

    <Col md={4} className="ms-n4">
      <h5>Edit Rates</h5>
      <RateInputs name="ratesheet" />
    </Col>
  </Row>
)

const ReadView = ({ component, hideRates }): JSX.Element => (
  <div className="d-flex flex-column gap-2">
    <h5>Details</h5>
    <Table>
      <thead>
        <tr>
          <th>Refreshment Type</th>
          <th>Name</th>
          <th>Description</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td aria-label="Refreshment Type">
            <TextDisplay value={component.refreshmentType} />
          </td>
          <td aria-label="Name">
            <TextDisplay value={component.name} />
          </td>
          <td aria-label="Description">
            <TextDisplay value={component.description} />
          </td>
        </tr>
      </tbody>
    </Table>
    {!hideRates && (
      <Col md={3}>
        <h5 className="mt-2">Rates</h5>
        <Pricing.View value={component.ratesheet} />
      </Col>
    )}
  </div>
)

const View = ({ component, editing = false, hideRates = false }): JSX.Element =>
  editing ? <WriteView /> : <ReadView component={component} hideRates={hideRates} />

export default View
