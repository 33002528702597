import React from "react"
import { Button, Col, Form, Row } from "react-bootstrap"
import { useDispatch, useSelector } from "react-redux"
import { Formik } from "formik"

import {
  getFieldValues,
  getSectionFieldNames,
  getFieldSettings,
} from "@/onboarding/features/product/selectors"
import { updateFields } from "@/onboarding/features/product/Slice"
import FormikBootstrap from "@/components/formik-bootstrap"
import { ReservedFields } from "@/onboarding/features/product/models/Product"
import LoadingSpinner from "@/components/LoadingSpinner"
import { useProductSubmitting } from "@/onboarding/features/product/helpers"
import FormikTextList from "@/onboarding/features/product/components/shared/FormikTextList"

const Edit = ({ setEditing }): JSX.Element => {
  const fieldNames = useSelector(getSectionFieldNames.additionalDetails)
  const initialValues = useSelector(getFieldValues(...fieldNames))
  const fieldsSettings = useSelector(getFieldSettings(...fieldNames))
  const dispatch = useDispatch()
  const [isSubmitting, setSubmitting] = useProductSubmitting()

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      onSubmit={(values) => {
        setSubmitting(true)
        dispatch(updateFields({ values }))
      }}
    >
      {({ handleSubmit, dirty }) => (
        <Form className="d-flex flex-column gap-4" onSubmit={handleSubmit}>
          <Row>
            <Col>
              <FormikBootstrap.TextInput
                name={ReservedFields.AGE_RESTRICTIONS}
                label="Age Restrictions"
                readOnly={!fieldsSettings[ReservedFields.AGE_RESTRICTIONS].writeable}
                showLockedIcon={!fieldsSettings[ReservedFields.AGE_RESTRICTIONS].writeable}
                showRequired={fieldsSettings[ReservedFields.AGE_RESTRICTIONS].required}
              />
            </Col>
            <Col>
              <FormikBootstrap.TextInput
                name={ReservedFields.HEIGHT_RESTRICTIONS}
                label="Height Restrictions"
                readOnly={!fieldsSettings[ReservedFields.HEIGHT_RESTRICTIONS].writeable}
                showLockedIcon={!fieldsSettings[ReservedFields.HEIGHT_RESTRICTIONS].writeable}
                showRequired={fieldsSettings[ReservedFields.HEIGHT_RESTRICTIONS].required}
              />
            </Col>
            <Col>
              <FormikBootstrap.TextInput
                name={ReservedFields.WEIGHT_RESTRICTIONS}
                label="Weight Restrictions"
                readOnly={!fieldsSettings[ReservedFields.WEIGHT_RESTRICTIONS].writeable}
                showLockedIcon={!fieldsSettings[ReservedFields.WEIGHT_RESTRICTIONS].writeable}
                showRequired={fieldsSettings[ReservedFields.WEIGHT_RESTRICTIONS].required}
              />
            </Col>
            <Col>
              <FormikBootstrap.TextInput
                name={ReservedFields.MEDICAL_CONDITION_RESTRICTIONS}
                label="Medical Condition Restrictions"
                readOnly={!fieldsSettings[ReservedFields.MEDICAL_CONDITION_RESTRICTIONS].writeable}
                showLockedIcon={
                  !fieldsSettings[ReservedFields.MEDICAL_CONDITION_RESTRICTIONS].writeable
                }
                showRequired={
                  fieldsSettings[ReservedFields.MEDICAL_CONDITION_RESTRICTIONS].required
                }
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <label>Does the tour provide:</label>
              <div className="ps-4">
                <FormikBootstrap.Switch
                  name={ReservedFields.INSTRUCTORS_PROVIDED}
                  label="Instructors"
                  disabled={!fieldsSettings[ReservedFields.INSTRUCTORS_PROVIDED].writeable}
                  showLockedIcon={!fieldsSettings[ReservedFields.INSTRUCTORS_PROVIDED].writeable}
                />
                <FormikBootstrap.Switch
                  name={ReservedFields.CHANGING_FACILITIES_PROVIDED}
                  label="Changing Facilities"
                  disabled={!fieldsSettings[ReservedFields.CHANGING_FACILITIES_PROVIDED].writeable}
                  showLockedIcon={
                    !fieldsSettings[ReservedFields.CHANGING_FACILITIES_PROVIDED].writeable
                  }
                />
                <FormikBootstrap.Switch
                  name={ReservedFields.STORAGE_LOCKERS_PROVIDED}
                  label="Storage Lockers"
                  disabled={!fieldsSettings[ReservedFields.STORAGE_LOCKERS_PROVIDED].writeable}
                  showLockedIcon={
                    !fieldsSettings[ReservedFields.STORAGE_LOCKERS_PROVIDED].writeable
                  }
                />
                <FormikBootstrap.Switch
                  name={ReservedFields.TOWELS_PROVIDED}
                  label="Towels For Water Activities"
                  disabled={!fieldsSettings[ReservedFields.TOWELS_PROVIDED].writeable}
                  showLockedIcon={!fieldsSettings[ReservedFields.TOWELS_PROVIDED].writeable}
                />
                <FormikBootstrap.Switch
                  name={ReservedFields.WAIVER_FORMS_REQUIRED}
                  label="Waiver forms to be signed"
                  disabled={!fieldsSettings[ReservedFields.WAIVER_FORMS_REQUIRED].writeable}
                  showLockedIcon={!fieldsSettings[ReservedFields.WAIVER_FORMS_REQUIRED].writeable}
                />
              </div>
            </Col>
            <Col>
              <label>Is the tour suitable for people with:</label>
              <div className="ps-4">
                <FormikBootstrap.Switch
                  name={ReservedFields.WHEELCHAIR_SUITABLE}
                  label="Wheelchairs"
                  disabled={!fieldsSettings[ReservedFields.WHEELCHAIR_SUITABLE].writeable}
                  showLockedIcon={!fieldsSettings[ReservedFields.WHEELCHAIR_SUITABLE].writeable}
                />
                <FormikBootstrap.Switch
                  name={ReservedFields.BLIND_SUITABLE}
                  label="Blindness"
                  disabled={!fieldsSettings[ReservedFields.BLIND_SUITABLE].writeable}
                  showLockedIcon={!fieldsSettings[ReservedFields.BLIND_SUITABLE].writeable}
                />
                <FormikBootstrap.Switch
                  name={ReservedFields.DEAF_SUITABLE}
                  label="Deafness"
                  disabled={!fieldsSettings[ReservedFields.DEAF_SUITABLE].writeable}
                  showLockedIcon={!fieldsSettings[ReservedFields.DEAF_SUITABLE].writeable}
                />
                <FormikBootstrap.Switch
                  name={ReservedFields.WALKING_DIFFICULTY_SUITABLE}
                  label="Walking Difficulties"
                  disabled={!fieldsSettings[ReservedFields.WALKING_DIFFICULTY_SUITABLE].writeable}
                  showLockedIcon={
                    !fieldsSettings[ReservedFields.WALKING_DIFFICULTY_SUITABLE].writeable
                  }
                />
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <FormikTextList
                name={ReservedFields.INCLUDED_EQUIPMENT}
                label="What equipment is included on tour?"
                readOnly={!fieldsSettings[ReservedFields.INCLUDED_EQUIPMENT].writeable}
                showLockedIcon={!fieldsSettings[ReservedFields.INCLUDED_EQUIPMENT].writeable}
                showRequired={fieldsSettings[ReservedFields.INCLUDED_EQUIPMENT].required}
              />
            </Col>
            <Col>
              <FormikTextList
                name={ReservedFields.GUEST_GUIDANCE}
                label="What should guests be advised to bring on tour?"
                readOnly={!fieldsSettings[ReservedFields.GUEST_GUIDANCE].writeable}
                showLockedIcon={!fieldsSettings[ReservedFields.GUEST_GUIDANCE].writeable}
                showRequired={fieldsSettings[ReservedFields.GUEST_GUIDANCE].required}
              />
            </Col>
          </Row>
          <div className="d-flex gap-2">
            <Button size="sm" type="submit" disabled={!dirty || isSubmitting}>
              <i className="fas fa-save me-2" />
              Save
            </Button>
            <Button
              size="sm"
              variant="outline-danger"
              onClick={() => setEditing(false)}
              disabled={isSubmitting}
            >
              <i className="fas fa-times me-2" />
              Cancel
            </Button>
            {isSubmitting && <LoadingSpinner />}
          </div>
        </Form>
      )}
    </Formik>
  )
}

export default Edit
