import React from "react"
import _ from "lodash"

import { parseTime, formatTime } from "@/components/DatePickers"

const VisitTimeDisplay = ({ time }): JSX.Element => {
  if (_.isNil(time)) {
    return null
  }

  return <>{formatTime(parseTime(time))}</>
}

export default VisitTimeDisplay
